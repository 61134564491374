<script setup>
/* === i18n === */
const { t } = useI18n()

/* === init === */
const globalContentStore = useGlobalContentStore()
const { footerLinks } = storeToRefs(globalContentStore)
</script>

<template>
  <footer>
    <section class="formal">
      <MainLogo class="main-logo" />

      <ul class="footer-navigation">
        <li v-for="link of footerLinks" :key="link.url">
          <NuxtLink :to="link.url"> {{ link.label }} </NuxtLink>
        </li>
      </ul>
    </section>
    <section class="sponsors">
      <NuxtLink href="https://www.bmkoes.gv.at/" target="_blank">
        <img
          src="~/assets/images/bmkoes-logo.svg?url"
          :alt="t('navigation.bmkoes')"
        />
      </NuxtLink>

      <NuxtLink href="https://next-generation-eu.europa.eu/" target="_blank">
        <img src="~/assets/images/eu-logo.svg?url" :alt="t('navigation.eu')" />
      </NuxtLink>

      <NuxtLink href="https://www.nhm-wien.ac.at/" target="_blank">
        <img
          src="~/assets/images/nhm-logo.svg?url"
          :alt="t('navigation.nhm')"
        />
      </NuxtLink>
    </section>
  </footer>
</template>

<style scoped lang="scss">
.main-logo {
  align-self: flex-start;
}

footer {
  background-color: var(--color-footer);
  padding: 1em max(4vmin, 20px) 0;
}
.formal {
  --color-logo: var(--color-whitish);
  --logo-length-custom: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3rem;
}

.footer-navigation {
  display: flex;
  flex-direction: column;
  align-items: right;
  color: var(--color-whitish);

  li {
    font-size: 1rem;
    height: 100%;
    text-align: right;

    p {
      width: 100%;
      color: var(--color-whitish);
      font-size: 0.5rem;
    }
  }
}

.sponsors {
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: var(--color-footer);
  gap: 1rem;
  padding: 1rem 0;
  max-width: 70em;
  margin: 0 auto;

  a {
    display: block;
    // max-width: 32%;
    height: 100%;
    flex: 1;

    img {
      margin: 0 auto;
      width: 14em;
      max-height: 3.7em;
    }
  }
  @include media('>phone') {
    gap: 2rem;
    padding: 5rem 2rem 2rem;
  }
}

@include media('>large') {
  .main-logo {
    align-self: unset;
  }

  .formal {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-navigation {
    flex-direction: row;
    align-content: right;
    li {
      a {
        margin-left: 1rem;
      }
    }
  }
}
</style>
