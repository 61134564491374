<script setup>
const { t } = useI18n()

const { currentScheme, schemes } = useColorScheme()

function getLocaleTitle(title) {
  return t(`navigation.${title}`)
}
</script>

<template>
  <ul class="color-mode-list">
    <li v-for="scheme of schemes" :key="scheme">
      <IconButton
        :button-title="getLocaleTitle(scheme.title)"
        :class="['mode-button', { active: currentScheme === scheme.mode }]"
        :icon-class="scheme.icon"
        @click="
          () => {
            currentScheme = scheme.mode
          }
        "
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
.color-mode-list {
  text-align: center;
  font-size: 2em;
  line-height: 1;

  li {
    display: inline-block;
    margin-right: 0.4rem;
  }
  .mode-button {
    opacity: 0.5;
    &.active,
    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  }
}

.mode-button {
  padding: 0.5rem;
  border-radius: 10px;
  @include action-state-colors;
}
</style>
