<script setup>
const { n, t } = useI18n()

const searchStore = useSearchStore()
const { totalHits, renderStatus } = storeToRefs(searchStore)

function posFormat(totalHits) {
  if (totalHits === 0) return 0.0
  if (searchStore.lastTileInView === null) return 0.0
  if (searchStore.lastTileInView === -Infinity) return 1.0
  return Math.min(searchStore.lastTileInView / totalHits, 1.0)
}

function scroll(pos) {
  window.scrollBy({
    top: window.innerHeight * pos,
    behavior: 'smooth',
  })
}
function isJune() {
  const now = new Date()
  return now.getMonth() === 5 // getMonth() gibt Monate von 0 (Januar) bis 11 (Dezember) zurück, also ist Juni 5
}
</script>

<template>
  <div>
    <Transition name="fade">
      <div v-if="totalHits > 0 && renderStatus !== null">
        <div class="results-indicator-plane">
          <div id="results-indicator">
            <div
              :class="[
                'result-bar',
                {
                  stalled: searchStore.renderStatus === 'stalled',
                  pride: isJune(),
                },
              ]"
              :data-pos="searchStore.lastTileInView"
              :style="`--pos: ${posFormat(totalHits)}`"
              @click.self.prevent="scroll(1)"
            >
              <div class="num-hits">
                {{ n(totalHits, 'integer') }}
                <span class="hits-caption">{{ t('navigation.hits') }}</span>
              </div>
              <div class="progress" @click.self.prevent="scroll(-1)"></div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.fade-enter-active {
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
}

.fade-leave-active {
  transition: 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.results-indicator-plane {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  top: 0;
  @include media('>=tablet') {
    bottom: 0.95rem;
    left: -1px;
    right: -1px;
    top: 0;
  }
}
#results-indicator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.result-bar {
  cursor: e-resize;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: 0.2s opacity;
  transition-delay: 700ms;
  opacity: 1;
  &.stalled {
    opacity: 0;
  }
  .num-hits {
    position: relative;
    font-family: var(--font-feature-display);
    z-index: 1;
    pointer-events: none;
    font-size: 1.2em;
    @include media('>=tablet') {
      font-size: 2.2em;
    }
  }
  .hits-caption {
    display: none;
    @include media('>=tablet') {
      display: inline;
    }
  }

  .progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(var(--pos) * 100%);
    background-color: hsl(106, 100%, 50%);
    cursor: w-resize;
    :root.dark & {
      background-color: hsl(299, 100%, 50%);
    }
  }
}
.pride {
  .progress {
    background: url('/progress-pride.png') left center / cover no-repeat;
    --offset: 35px;
    --pos-max: 0.35;
    background-position-x: clamp(
      calc(var(--offset) * -1),
      calc(var(--offset) * -1 + var(--offset) * (var(--pos) / var(--pos-max))),
      0px
    );
    @include media('>=tablet') {
      --offset: 60px;
      --pos-max: 0.1;
    }
  }
  html.light & .num-hits {
    text-shadow:
      0 0 5px #fff,
      0 0 5px #fff,
      0 0 5px #fff;
  }
}
</style>
