<script setup lang="ts">
const router = useRouter()
const head = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
})
useHead({
  htmlAttrs: {
    lang: head.value.htmlAttrs.lang,
    dir: head.value.htmlAttrs.dir,
  },
})
</script>

<template>
  <div class="main-content">
    <header class="main-header">
      <TheNavigationHeader />
    </header>
    <slot></slot>
    <TheMainFooter
      v-if="!router.currentRoute.value.meta?.hideFooter"
      class="main-footer"
    />
  </div>
</template>

<style lang="scss" scoped>
.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main-header {
  background-color: var(--color-background);
  left: 0;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 20;
  font-weight: 500;
}
.main-footer {
  margin-top: auto;
}
</style>
